import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/common/services/page/page.service';
import { HomeService } from '../../services/home.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { ModalController, NavController } from '@ionic/angular';
import { CategoryPage } from '../../../common/pages/category/category.page';
import { NavigationExtras, Router } from '@angular/router';
import { CategoryComponent } from 'src/app/common/components/category/category.component';
import { AlertService } from 'src/app/common/services/alert/alert.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { environment } from 'src/environments/environment';
import { LobbyPage } from 'src/app/lobby/pages/lobby/lobby.page';
import { UserService } from 'src/app/user/services/user.service';
import { UserPage } from 'src/app/user/pages/user.page';
import { VideoModelPage } from 'src/app/common/pages/video-model/video-model.page';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { GetStartedPage } from 'src/app/get-started/pages/get-started.page';
import { WelcomePage } from 'src/app/welcome/pages/welcome.page';
import { RegistrationPage } from 'src/app/registration/pages/registration.page';
import { LoginPage } from 'src/app/login/pages/login.page';
import { ForgetPasswordPage } from 'src/app/forgot-password/pages/forget-password.page';



@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  
  loginForm: FormGroup;
  pageData: any = [];
  eventList: any = [];
  public static pageId: number = 7;
  changeText: any;
  color: string = '';
  logo: any;
  backgroundImage: string = environment.bannerDefault;
  eventCatList: any = [];
  user: any = [];
  userId: number;
  briefCase: any = [];
  userRoles: any = [];
  actmenu = 'Sector';
  plas: any = [];
  pack: any = [];

  preexp: any = [];

  latexp: any = [];

  conexp: any = [];

  place1: any = [];
  place2: any = [];
  place3: any = [];

  slides = [
    {img: "https://via.placeholder.com/714x315/000000"},
    {img: "https://via.placeholder.com/714x315/111111"},
    {img: "https://via.placeholder.com/714x315/333333"},
    {img: "https://via.placeholder.com/714x315/111111"},
  ]; 
  categories: any;

  constructor(public homeService: HomeService, public pageService: PageService,
    public toastService: ToastService, public modalController: ModalController,
    public router: Router, public authHelperService: AuthHelperService,
    public alertService: AlertService, public userService: UserService,
    public loadingService: LoadingService,
    public navCtrl: NavController,
    public userPage: UserPage, private formBuilder: FormBuilder) {
    this.changeText = true; 
    this.userId = this.authHelperService.getUserId();
    this.userRoles = this.authHelperService.getRoles();
 

    this.loginForm = this.formBuilder.group({
      user_email: ['', [Validators.required, Validators.email]],
      user_name: ['', Validators.required]
    });

    var dat = this.pageService.getValue('currentpage');    
    
    this.pageService.storeValue(window.location.pathname, 'currentpage');
    
    this.router.navigate([dat]);
   }


  slideConfig = {"autoplay": true,
  "autoplaySpeed": 2000,  "dots": true,  "infinite": true, "speed"  : 500,  "fade": true,
  "cssEase": 'linear'};
  
  addSlide() {
    this.slides.push({img: "http://via.placeholder.com/714x315/777777"})
  }
  

  slideConfigList = {"slidesToShow": 5, "slidesToScroll": 5,   "autoplay": true,
  "autoplaySpeed": 2000,  "dots": false,    "arrows": true, "infinite": true, "speed"  : 500,  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 5,
        "slidesToScroll":5,
        "infinite": true,
        "dots": true
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2, 
        "slidesToScroll": 2,
        "dots": false
      }
    }]
  };

  
  slideConfigLists = {"slidesToShow": 5, "slidesToScroll": 5,   "autoplay": true,
  "autoplaySpeed": 2000,  "dots": false,    "arrows": true, "infinite": true, "speed"  : 500,  "responsive": [
    {
      "breakpoint": 1024,
      "settings": {
        "slidesToShow": 5,
        "slidesToScroll":5,
        "infinite": true,
        "dots": true
      }
    },
    {
      "breakpoint": 600,
      "settings": {
        "slidesToShow": 2, 
        "slidesToScroll": 2,
        "dots": false
      }
    }]
  };
   

  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  viewMystall(exi){ 
    var exIds = [] ;

    // var exIds = [] ;
    // this.exhibitors.forEach(function(item){   
    //   exIds.push(item.exId);
    // });  
    // exIds.sort(this.compareNumbers);
    // this.pageService.storeValue(exi, 'cur_index');
    // this.pageService.storeValue(exIds, 'exIds');

    this.latexp.forEach(function(item){   
      exIds.push(item.exId);
    });  


    // exIds.sort(this.compareNumbers);

    var index = this.latexp.indexOf(exi);

 
    this.pageService.storeValue(index, 'cur_index');
    this.pageService.storeValue(exIds, 'exIds');
   
    let navigationExtras: NavigationExtras = {
      state: {
        exId: exi.exId 
      }
    };

    this.loadingService.present();
        

    setTimeout(() => {
      this.loadingService.dismiss();
      this.navCtrl.navigateForward(['stall'], navigationExtras);
    }, 1000); 
   
  }

  compareNumbers(a, b) {
    return a - b;
  }

  viewMystallpreexp(exi){ 
    var exIds = [] ;
 
    this.preexp.forEach(function(item){   
      exIds.push(item.exId);
    });  
 
    
    // exIds.sort(this.compareNumbers);

  
    // console.log(exIds);

    // return;
    // this.pageService.storeValue(exi, 'cur_index');
    // this.pageService.storeValue(exIds, 'exIds');

     

    // console.log(index);
    // console.log(exIds);

    // return index;

    var index = this.preexp.indexOf(exi);

 
    this.pageService.storeValue(index, 'cur_index');
 

    // this.pageService.storeValue(exi.exId, 'cur_index');
    this.pageService.storeValue(exIds, 'exIds');
   
    let navigationExtras: NavigationExtras = {
      state: {
        exId: exi 
      }
    };

    this.loadingService.present();
        

    setTimeout(() => {
      this.loadingService.dismiss();
      this.navCtrl.navigateForward(['stall'], navigationExtras);
    }, 1000); 
   
  }
  

  
  
  viewMystalladv(exi){ 
    exi  = parseInt(exi);

    var exIds = [] ;
 
    this.place2.forEach(function(item){   
      exIds.push(parseInt(item.home_advertisement_stall));
    });  
    this.place1.forEach(function(item){   
      exIds.push(parseInt(item.home_advertisement_stall));
    });  
 
    var index = exIds.indexOf(exi);
    console.log(exIds);
    console.log(exi);
    console.log(index);
 
    this.pageService.storeValue(index, 'cur_index');
 
    this.pageService.storeValue(exIds, 'exIds');
   
    let navigationExtras: NavigationExtras = {
      state: {
        exId: exi 
      }
    };

    this.loadingService.present();
        

    setTimeout(() => {
      this.loadingService.dismiss();
      this.navCtrl.navigateForward(['stall'], navigationExtras);
    }, 1000);
 
   
  }
  
  slickInit(e) {
    // console.log('slick initialized');
  }
  
  breakpoint(e) {
    // console.log('breakpoint');
  }
  
  afterChange(e) {
    // console.log('afterChange');
  }
  
  beforeChange(e) {
    // console.log('beforeChange');
  }


  
  checkSector(group){ 

    this.actmenu= group;

    this.homeService.getMenuList(group).subscribe(value => {
      this.plas = value.data?.PLASTICS; 
      this.pack = value.data?.PACKAGING; 
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });

    
    
  }
  ngOnInit() {

    this.pageService.removeValue('cat_level1');
    this.pageService.removeValue('cat_level2');
    this.pageService.removeValue('cat_level3'); 


    this.getInitData();
    if (this.authHelperService.getToken() != null) {
      this.getUserProfile();
      this.getBriefCase();
      this.initStatus();
    }  
    this.homeService.getMenuList('Sector').subscribe(value => {
      this.plas = value.data?.PLASTICS; 
      this.pack = value.data?.PACKAGING; 
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });

    var highlightedItems = document.querySelectorAll(".f-logo");
    highlightedItems.forEach(function (logolist) {
      logolist.classList.remove("acs");
    });

    this.homeService.premiumExhibitor().subscribe(value => {
      this.preexp = value.data.exhibitor;

    

    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });

    this.homeService.latestxhibitor().subscribe(value => {
      this.latexp = value.data.exhibitor;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });

    this.homeService.countryexhibitor().subscribe(value => {
      this.conexp = value.data;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    }); 

    this.homeService.get_banners('Place1').subscribe(value => {
      this.place1 = value.data; 
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    }); 

    this.homeService.get_banners('Place2').subscribe(value => {
      this.place2 = value.data;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    }); 

    this.homeService.get_banners('Place3').subscribe(value => {
      this.place3 = value.data[0];
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    }); 
 
    this.pageService.storePageData(GetStartedPage.pageId).then(value => {
      this.pageData = value;
      this.categories = value.pageData.categories;
    });
    this.pageService.storePageData(WelcomePage.pageId).then(value => {

      this.pageService.loadImages(value.pageData.events).then(value => {

      });
    });

    this.pageService.storePageData(RegistrationPage.pageId).then(value => {
      console.log('page ' + RegistrationPage.pageId + ' loaded');
    });
    this.pageService.storePageData(LoginPage.pageId).then(value => {
      console.log('page ' + LoginPage.pageId + ' loaded');
    });
    this.pageService.storePageData(ForgetPasswordPage.pageId).then(value => {
      console.log('page ' + ForgetPasswordPage.pageId + ' loaded');
    });
    this.pageService.storePageData(HomePage.pageId).then(value => {
      console.log('page ' + HomePage.pageId + ' loaded');
    });
  }


   
  ionViewWillEnter() {
    this.getEventList();
     this.pageService.removeValue('cat_level1');
    this.pageService.removeValue('cat_level2');
    this.pageService.removeValue('cat_level3'); 
    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
    this.changeText = true;
  }

  async onHelpdeskClicked() {
    // const modal = await this.modalController.create({
    //   component: HelpdeskModalPage,
    //   cssClass: 'stallfeedback', 
    //   componentProps: { 
    //     'titleData': 'inquiryForm', 
    //     'page': 'lobby'
    //   }
    // });  

    // setTimeout(function () {
    //   let video = document.querySelector('#lobbyvideo');
    //   var str = video.getAttribute("src");
    //   var res = str.replace("autoplay=1", "autoplay=0");
    //   video.setAttribute("src", res);
    // }, 100);

    const modal = await this.modalController.create({
      component: VideoModelPage,
      cssClass: 'videopopup',
      componentProps: {
        'videoList': { url: 'https://www.youtube.com/watch?v=i9ao48Cnfcs' },
        'multiple': false
      }
    });

    modal.onDidDismiss().then((value: any) => {
      // setTimeout(function () {
      //   let video = document.querySelector('#lobbyvideo');
      //   var str = video.getAttribute("src");
      //   var res = str.replace("autoplay=0", "autoplay=1");
      //   video.setAttribute("src", res);
      // }, 100);
    });
    return await modal.present();
  }

  initStatus() {
    let data = {
      "status": 'offline',
      "stallstatus": 'offline',
      date: Date.now(),
    }
    this.pageService.addUser(data);
  }

  addClass(event): void {

    //  console.log(event.target); 
    //document.querySelectorAll('.f-logo');

    var highlightedItems = document.querySelectorAll(".f-logo");
    highlightedItems.forEach(function (logolist) {
      logolist.classList.remove("acs");
    });
    //.classList.remove("acs");
    this.changeText = false;
    event.srcElement.classList.add("acs");
    //event.target.className += 'myClass';
  }

  removeClass(event): void {
    //document.querySelector('.f-logo').classList.remove("acs");
    // event.srcElement.classList.remove("acs");
    //  event.target.className = event.target.className.replace('myClass', '');
  }
  getInitData() {
    this.pageService.storePageData(HomePage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          }
        });
      }
    });
    this.pageService.storePageData(LobbyPage.pageId).then(value => { });
  }

  onLoginClicked() { 
    this.loadingService.present();
    this.homeService.saveDemo(this.loginForm.value).subscribe(value => {
      this.loadingService.dismiss();
    this.toastService.presentToast({ message: 'Successfully Submitted', status: 'success' });
    this.loginForm.reset();
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: 'Successfully Submitted', status: 'success' });
      this.loginForm.reset();
    }); 
  } 

  getEventList() {
    this.homeService.getEventList().subscribe(value => {
      this.eventList = value.data;
      if (this.eventList.length <= 0) {
        this.router.navigate(['/info-template'], { skipLocationChange: true });
      } else {
        this.eventList = value.data[0];
        this.pageService.storeValue({ id: this.eventList.eveId, logo: this.eventList.file.atta_url, name: this.eventList.eveName }, 'event');
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  async showCategoryModal(event) {
    const modal = await this.modalController.create({
      component: CategoryComponent,
      cssClass: 'regpop',
      componentProps: {
        'eventId': event.eveId,
        'eventImg': event.file.atta_url,
        'category': event.event_category
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data != undefined && data.data.reg) {
        this.pageService.storeValue({ id: event.eveId, logo: event.file.atta_url, name: event.eveName }, 'event');
        this.router.navigateByUrl('lobby');
      }
    });
    return await modal.present();
  }

  onEnterNowClicked(event) {
    let userid = this.authHelperService.getUserId();
    let vis = false;
    event.event_registration.forEach(element => {
      if (element.useId == userid && vis != true) {
        vis = true;
      }
    });
    if (vis) {
      this.homeService.sendExhibitorEmail(event.eveId).subscribe(value => { }, error => { console.log(error) });
      this.pageService.storeValue({ id: event.eveId, logo: event.file.atta_url, name: event.eveName }, 'event');
      this.router.navigateByUrl('lobby');
    } else {
      this.showCategoryModal(event);
    }
  }

  onLogoutClicked() {
    this.alertService.logoutAlert();
  }

  onLogoHover(event) {
    console.log('logo hovered' + event.eveId);
  }

  getUserProfile() {
    this.pageService.getUserProfile().then(value => {
      this.user = value;
    });
  }

  getBriefCase() {
    this.userService.getMyBrief(this.userId).subscribe(value => {
      this.briefCase = value.data;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onDownloadDataClicked() {
    this.userPage.onDownloadDataClicked();
  }

  // onCategoryClicked(type) {
  //   if ('event_category' in this.eventList) {
  //     var category = this.eventList.event_category.find((ele) => ele.category_item.catItemSlug == type);
  //     if (category) {
  //       this.pageService.storeValue({ id: category.caiId, name: category.category_item.catItemName, type: 'womms' }, 'cat_level1');
  //       this.router.navigateByUrl('category');
  //     }
  //   }
  // }
  onCategoryClicked(level1, level2, level3) {
   
    this.pageService.storeValue({ "id": level1.caiId, "name": level1.catItemName, "type": "womms" }, 'cat_level1');
    this.pageService.storeValue({ "id": level2.caiId, "name": level2.catItemName, "pId": level1.caiId }, 'cat_level2');
    this.pageService.storeValue({ "id": level3.caiId, "name": level3.catItemName, "pId": level2.caiId }, 'cat_level3');
 
    this.router.navigateByUrl('exhibitors');
  }
  onAssociClicked() {
    this.pageService.storeValue({ id: undefined, name: 'Associations', type: 'associations' }, 'cat_level1');
    this.router.navigateByUrl('associations-list');
  }
  onCountryClicked() {
    this.pageService.storeValue({ id: undefined, name: 'Country', type: 'country' }, 'cat_level1');
    this.router.navigateByUrl('countries-list');
  }
}
