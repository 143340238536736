import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../toast/toast.service';
import { Observable } from 'rxjs';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { LoadingService } from '../loading/loading.service';
import { UserService } from 'src/app/user/services/user.service';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { HomeService } from 'src/app/home/services/home.service';
import { EventService } from 'src/app/chat/service/event.service';
import { AuthGuard } from 'src/app/auth.guard';
import { LoginTwoPage } from 'src/app/login/pages/login-two/login-two.page';

firebase.initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class PageService extends BaseService {

  userId: number;
  public static notificationCount: number = 0;
  messageList: any = [];
  eventList: any = [];
  currentPageData: any;

  constructor(http: HttpClient, public toastService: ToastService, public authHelperService: AuthHelperService,
    public loadingService: LoadingService, public userService: UserService, public navCtrl: NavController,
    public modalController: ModalController, public router: Router, public homeService: HomeService,
    public events: EventService) {
    super(http);
  }

  getPageData(id) {
    return super.get<any>('page/' + id, false);
  }

  getSiteInfo(): Promise<any> {
    return new Promise(resolve => {
      if (this.getValue('siteinfo')) {
        resolve(this.getValue('siteinfo'));
      } else {
        super.get<any>('siteinfo', false).subscribe(value => {
          this.storeValue(value, 'siteinfo');
          resolve(value);
          return value;
        }, error => {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    })
  };

  loadImages(images): Promise<any> {
    return new Promise(resolve => {
      images.forEach(function (img) {
        var images = new Image();
        images.src = img.atta_url;
      });
      resolve('images loaded');
    });
  }

  storePageData(pageId): Promise<any> {
    return new Promise(resolve => {
      if (this.getValue('page' + pageId)) {
        resolve(this.getValue('page' + pageId));
      } else {
        this.getPageData(pageId).subscribe(value => {
          value.page_component.forEach(function (img) {
            if (img.paComponentType == 'image' || img.paComponentType == 'bg-image') {
              var images = new Image();
              images.src = img.image.atta_url;
            }
          });
          this.storeValue(value, 'page' + pageId);
          resolve(value);
        }, error => {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    });
  }

  //stallassets
  // preLogin(pageId) {
  //   this.getPageData(pageId).subscribe(value => {
  //     value.page_component.forEach(function (img) {
  //       if (img.paComponentType == 'image' || img.paComponentType == 'bg-image') {
  //         var images = new Image();
  //         images.src = img.image.atta_url;
  //       }
  //     });
  //     this.storeValue(value, 'page' + pageId);
  //     return value;
  //   }, error => {
  //     this.toastService.presentToast({ message: error.error.message, status: 'error' });
  //   });
  // }


  stallassets() {
    super.get<any>('stallassets', true).subscribe(value => {
      value['data'].forEach(element => {
        var images = new Image();
        images.src = element.furl;
      });
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  getAssets(arrayVar: Array<any>, value: any) {
    let item = arrayVar.find(i => i.identifier == value);
    return (item != undefined) ? item.atta_url : false;
  }

  storeValue(data, name) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  getValue(name) {
    return JSON.parse(localStorage.getItem(name));
  }

  removeValue(name) {
    localStorage.removeItem(name);
  }

  getProfile(id) {
    return super.get<any>('getuser/' + id, true);
  }

  getUserProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.getValue('userInfo') != null) {
        resolve(this.getValue('userInfo'));
      }
      else {
        this.userId = this.authHelperService.getUserId();
        this.getProfile(this.userId).subscribe(value => {
          this.storeValue(value.data, 'userInfo');
          resolve(value.data);
        }, error => {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    });
  }

  getHelpDesk() {
    return super.get<any>('helpdesk', true);
  }

  storeHelpdesk(data) {
    return super.post<any>('helpdesk', true, data);
  }

  sendAttachmentEmail(id) {
    this.loadingService.present();
    this.userService.emailAttachment({ id: id }).subscribe(value => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: value.message, status: 'success' });
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' })
    });
  }

  printAttachment(url) {
    let printContents, popupWin;
    var extension = url.split('.').pop();
    if (extension == 'pdf') {
      window.open(url, '_blank', 'fullscreen=yes');
    }
    else {
      printContents = (extension == 'pdf') ? `<iframe src="${url}" toolbar="no"></iframe>` : `<img src="${url}">`;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
      );
      popupWin.document.close();
    }
  }

  addUser(data) {
    var userId = this.authHelperService.getUserId();
    firebase.database().ref('users').child(userId).update(data);
  }

  // Notification Start
  addNotifications(data, partnerId) {
    firebase.database().ref('notifications').child(partnerId).push(data);
  }

  showNotifications() {
    var data = [];
    firebase.database().ref('notifications').child(this.authHelperService.getUserId()).on('value', (snapshot) => {
      let event = this.getValue('event');
      var res = {}, j = 0;
      data = [];
      res = snapshot.val();
      for (let i in res) {
        if (res[i].status == 'unread' && res[i].eventId == event.id) {
          data[j++] = res[i];
          this.userService.getProfile(res[i].sender).subscribe(value => {
            res[i].key = i;
            res[i].sendername = value.data.use_name;
            if (res[i].type == "videocall") {
              this.toastService.videoNotification(res[i]);
            } else if (res[i].type == "network") {
              this.toastService.notificationToast(res[i]);
            } else {
              this.toastService.notificationToast(res[i]);
            }
            // this.updateNotifcation(res[i].sender, {status: 'read'});
          });
        }
      }
    });
  }

  updateNotifcation(sender, data) {
    firebase.database().ref('notifications').child(this.authHelperService.getUserId()).once('value', (snapshot) => {
      var res = {};
      res = snapshot.val();
      for (let i in res) {
        if (res[i].status == 'unread' && res[i].sender == sender) {
          firebase.database().ref('notifications').child(this.authHelperService.getUserId()).child(i).update(data);
        }
      }
    });
  }

  getNotificationCount() {
    if (this.authHelperService.getUserId()) {
      firebase.database().ref('notifications').child(this.authHelperService.getUserId()).on('value', (snapshot) => {
        var res = {};
        res = snapshot.val();
        PageService.notificationCount = 0;
        let event = this.getValue('event');
        for (let i in res) {
          if (res[i].status == 'unread' && res[i].eventId == event.id) {
            ++PageService.notificationCount;
          }
        }
      });
    }
  }

  getNotifications(type): Promise<any> {
    var data = [], data1 = [], index = 0;
    let event = this.getValue('event');
    return new Promise((resolve, reject) => {
      firebase.database().ref('notifications').child(this.authHelperService.getUserId()).once('value', (snapshot) => {
        var res = {}, j = 0;
        res = snapshot.val();
        for (let i in res) {
          res[i].key = i;
          if (type == 'unread' && res[i].status == 'unread' && res[i].eventId == event.id) {
            this.userService.getProfile(res[i].sender).subscribe(value => {
              res[i].sender = value.data;
            });
            data[j++] = res[i];
          }
          else if (type != 'unread' && res[i].eventId == event.id) {
            this.userService.getProfile(res[i].sender).subscribe(value => {
              res[i].sender = value.data;
            });
            data[j++] = res[i];
          }
        }
      });
      firebase.database().ref('notifications').child(this.authHelperService.getUserId()).orderByKey().once('value', (snapshot) => {
        this.messageList = [];
        this.messageList = snapshot.val();
        for (let i in this.messageList) {
          this.messageList[i].key = i;
          if (type == 'unread' && this.messageList[i].status == 'read' && this.messageList[i].eventId == event.id && index < 10) {
            this.userService.getProfile(this.messageList[i].sender).subscribe(value => {
              this.messageList[i].sender = value.data;
            });
            data1[index++] = this.messageList[i];
          }
        }
      });
      resolve([data, data1]);
    });
  }
  // Notification Ends

  goChatModel(val) {
    let navigationExtras: NavigationExtras = {
      state: {
        partneruid: val.sender
      }
    };
    this.navCtrl.navigateForward(['chat'], navigationExtras);
  }

  goToVideoChat(val) {
    let navigationExtras: NavigationExtras = {
      state: {
        exhibitorData: val.exhibitorData,
        eventId: val.eventId,
        curExid: val.exhibitorData.exId,
      }
    };
    this.navCtrl.navigateForward(['videochat/landing'], navigationExtras);
  }

  goToStall(val) {
    let navigationExtras: NavigationExtras = {
      state: {
        eventId: val.eventId,
        curExid: val.exhibitorId,
      }
    };
    this.navCtrl.navigateForward(['stall-chat'], navigationExtras);
  }

  async showErrorComponent(error) {
    if (error.status == 403) {
      const modal = await this.modalController.create({
        component: ErrorComponent,
        cssClass: 'popup-modal',
        backdropDismiss: false,
        componentProps: {
          message: error.error.message
        }
      });
      return await modal.present();
    }
    else {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    }
  }

  checkEvent() {
    var event = this.getValue('event');
    if (event == null || event == '') {
      this.getEventList();
      // this.router.navigate(['/home']);
    }
  }

  logout(id) {
    return super.get<any>('logout-notification/' + id, false);
  }

 
  getEventList() {
    this.homeService.getEventList().subscribe(value => {
      this.eventList = value.data;
      if (this.eventList.length > 0) {
        this.storeValue({ id: this.eventList[0].eveId, logo: this.eventList[0].file.atta_url, name: this.eventList[0].eveName }, 'event');
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  saveRoute(type, route, navigationextras = null) {
    if (!this.authHelperService.isLogged()) {
      if (type != 'router') navigationextras = this.router.url;
      this.storeValue({ type: type, value: route, navigationExtras: navigationextras }, 'redirectTo');
    }
  }

  redirect() {
    
//     var dat = this.pageService.getValue('currentpage');
    
// this.router.navigate([dat]);
    var redirect = this.getValue('currentpage');
    if ("value" in redirect) {
      if (redirect.type == 'router' && redirect.navigationExtras != null) this.router.navigate([redirect.value], redirect.navigationExtras);
      else if (redirect.type == 'router' && redirect.navigationExtras == null) this.router.navigate([redirect.value]);
      else {
        this.router.navigate([redirect.navigationExtras]);
        this.publishEvent(redirect.value);
      }
    }
    this.removeValue('redirectTo');
  }

  async goTologin() {
    const modal = await this.modalController.create({
      component: LoginTwoPage,
      cssClass: 'popup3'
    });
    modal.onDidDismiss().then((data: any) => {
      if (data.data) {
        if (!data.data.status) {
          let navigationExtras: NavigationExtras = {
            state: {
              user_email: data.data.user_email
            }
          };
          // this.router.navigate(["/register"], navigationExtras);
        }
      }
    });
    return await modal.present();
  }

  publishEvent(event) {
    this.events.publish(event);
    this.events.destroy(event);
  }

  getExhibitorPreData() {
    // var catParent = this.getValue('cat_level2');
    // var level1 = this.getValue('cat_level1');
    // var event = this.getValue('event');
    // if (catParent == null || catParent == undefined) {
    //   if (level1 != null && level1 != undefined) {
    //     if (level1.type == 'womms') this.navCtrl.navigateRoot('category');
    //     if (level1.type == 'associations') this.navCtrl.navigateRoot('associations-list');
    //     if (level1.type == 'country') this.navCtrl.navigateRoot('countries-list');
    //     this.removeValue('cat_level2');
    //   } else this.navCtrl.navigateRoot('home');
    // }
    // var data = { id: event.id, catId: catParent.id, type: level1.type };
    // if (level1.type == 'associations') {
    //   var associationCategory = this.getValue('association-category');
    //   data['assId'] = associationCategory.id;
    // } else if (level1.type == 'country') {
    //   var countryCategory = this.getValue('country-category');
    //   data['assId'] = countryCategory.name;
    // }
    // return data;
  }

}